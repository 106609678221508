import { environment } from '../../../../environments/environment';

export function resolveUrl(url: string) {
  if (environment.settings.baseUrl) {
    url = `${environment.settings.baseUrl.slice(0, -1)}${url}`;
  } else {
    url = `/backend/api${url}`;
  }

  return url;
}
